import { useState, useContext, useEffect } from 'react'
import { m as motion } from 'framer-motion'
import isMobile from 'is-mobile'

import { ScrollContext } from '../context/ScrollContext'
import { Game } from './Game'
import { Button } from './Button'
import { SearchResultType } from './SearchResults'
import { Tag } from './Tag'

const grid = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
  hidden: {
    opacity: 0,
  },
}

const item = {
  visible: { opacity: 1, scale: 1 },
  hidden: { opacity: 0, scale: 0.95 },
}

const pageSize = 36

export const GameGrid = ({ items, smCols = "grid-cols-2", loadMoreButton = true }) => {
  const [limit, setLimit] = useState(pageSize)

  const paginatedItems = items.slice(0, limit)
  const canLoadMore = paginatedItems.length < items.length

  function loadMore() {
    setLimit(limit + pageSize)
  }


  const { scrollTop } = useContext(ScrollContext)
  
  useEffect(() => {
    if (window.innerWidth > 640) return
    const scrollPosition = window.innerHeight + document.documentElement.scrollTop
    const bottomEdge = document.scrollingElement.scrollHeight
    const isBottomReached = scrollPosition >= bottomEdge - 100
    if (isBottomReached) loadMore()
  }, [scrollTop])

  function renderItem(item) {
    if (item.type === SearchResultType.Tag)
      return <Tag tag={item.data} />
    else if (item.type === SearchResultType.Game)
      return <Game game={item.data} />
    else
      return <Game game={item} />
  }

  return (
    <div className="flex flex-col items-center w-full px-4 pb-10 sm:px-10">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={isMobile() || grid}
        className={`grid w-full ${smCols} md:grid-cols-2 gap-3 sm:gap-10 lg:grid-cols-3 xl:grid-cols-4`}
      >
        {paginatedItems.map((item, index) => (
          <motion.div variants={isMobile() || item} key={index}>
            {renderItem((item))}
          </motion.div>
        ))}

        {loadMoreButton && canLoadMore &&
          <motion.div className="my-4 col-span-full hidden md:flex justify-center" variants={item} key="button">
            <Button onClick={loadMore} className="h-12 px-10 text-xl">Load More</Button>
          </motion.div>
        }
      </motion.div>

    </div>
  )
}
