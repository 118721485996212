import { useState, useContext } from 'react';
import { ScrollContext } from '../context/ScrollContext';
import { GamesContext } from '../context/GamesContext';
import { Balance } from './Balance';
import { BigWinners } from './BigWinners';
import { ButtonSettings } from './ButtonSettings';
import { Divider } from './Divider';
import { Logo } from './Logo';
import { Search } from './Search';
import { SettingsPanel } from './SettingsPanel';
import { MobileSearch } from './MobileSearch';
import { isBannerEnabled } from '../utils/data';
import { BannerLogo } from './BannerLogo';

export const Header = () => {
  const { scrollTop } = useContext(ScrollContext)
  const { search } = useContext(GamesContext)

  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const banner = isBannerEnabled()

  return (
    <div className="fixed top-0 z-30 w-full" style={{maxWidth: 1920}}>
      <div className="relative z-30 flex items-center justify-between py-1 md:p-3 text-white bg-black shadow-md">
        <div className="relative z-10 flex items-center pl-3">
          <Logo className="w-8 md:w-12 ml-1 md:ml-0" />
          <Divider />
          <Search className="hidden md:block" />
        </div>

        <BigWinners className="z-40" />

        <div className="flex items-center">
          {banner ?
            <>
              <BannerLogo className="w-8 md:w-12 ml-1 md:ml-0" />
              <Divider className="pr-2" />
            </>
            : <Divider className="hidden sm:block" />
          }
          <Balance />
          <Divider />
          <ButtonSettings
            isPanelOpen={isPanelOpen}
            onClick={() => setIsPanelOpen(!isPanelOpen)}
            className="md:mr-1"
          />
        </div>

        <SettingsPanel
          isPanelOpen={isPanelOpen}
          onClose={() => setIsPanelOpen(false)}
        />
      </div>
      <MobileSearch isVisible={scrollTop > 50 || search.length} />
    </div>
  );
};
