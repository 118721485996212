import React, { useEffect, useRef } from 'react'


export enum ImageSize {
  Small = 'sm',
  Medium = 'md',
  Large = 'lg'
}

export interface ImageSizes {
  [ImageSize.Small]: ImageFormats;
  [ImageSize.Medium]: ImageFormats;
  [ImageSize.Large]: ImageFormats;
  blurHash?: string;
}

export enum ImageFormat {
  AVIF = "avif",
  WebP = "webp",
  PNG = "png"
}

export interface ImageFormats {
  [ImageFormat.AVIF]: string;
  [ImageFormat.WebP]: string;
  [ImageFormat.PNG]: string;
}

export type SizeMap = [key: string, value: ImageSize][];


function nextSize(currentSize: ImageSize) {
  switch (currentSize) {
    case ImageSize.Small: return ImageSize.Medium
    case ImageSize.Medium: return ImageSize.Large
    case ImageSize.Large: return ImageSize.Large
  }
}

function sortedFormats(formats: ImageFormats): [ImageFormat, string][] {
  const order = {
    [ImageFormat.AVIF]: 1,
    [ImageFormat.WebP]: 2,
    [ImageFormat.PNG]: 3
  }

  return (Object.entries(formats) as [ImageFormat, string][])
    .sort(([formatA, _contentA], [formatB, _contentB]) => {
      return order[formatA] - order[formatB]
    })
}

export const SizeMapPresets = {
  alwaysSmall: [
    ["", ImageSize.Small]
  ]
}


interface PictureProps {
  imageSizes: ImageSizes;
  sizeMap: SizeMap;
  fallbackSize: ImageSize;
  onLoad: () => void;
  onUnload: () => void;
  alt?: string;
  retinaSupport?: boolean;
  className?: string;
  width?: number;
  height?: number;
}

export const Picture: React.FC<PictureProps> = ({ 
  imageSizes, 
  sizeMap, 
  fallbackSize, 
  onLoad, 
  onUnload, 
  alt, 
  retinaSupport, 
  className,
  width,
  height
}) => {
  const ref = useRef<HTMLImageElement | null>(null)
  let image: HTMLImageElement

  useEffect(() => {
    if (!ref.current) return
    image = ref.current

    const isImageLoaded = image.naturalWidth !== 0
    if (isImageLoaded) {
      onLoad()
    } else {
      image.onload = onLoad
    }
    
    return () => {
      if (image) { 
        image.onload = null
      }
      onUnload()
    }
  }, [imageSizes])

  const sources = sizeMap.map(([media, size]) => {
    const formats = sortedFormats(imageSizes[size])
    const nextSizeFormats = imageSizes[nextSize(size)]
    return formats.map(([format, src]) => {
      const nextSizeSrc = nextSizeFormats[format]
      const type = `image/${format}`
      const srcSet = retinaSupport ? `${src}, ${nextSizeSrc} 2x` : src
      return <source {...{ media, srcSet, type }} key={media + format} />
    })
  })

  const fallback = <img 
    src={imageSizes[fallbackSize].png} 
    {...{ className, alt, width, height, ref }} 
  />

  return (
    <picture>
      {sources}
      {fallback}
    </picture>
  )
}