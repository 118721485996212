import { ReactComponent as EmptyStarIcon } from '../images/star-empty.svg'
import { ReactComponent as FilledStarIcon } from '../images/star-filled.svg'

export function StarButton({ isFilled, onClick }) {
  return (
    <div 
      className={`
        p-2 sm:p-4 md:p-6 
        ${isFilled ? 'opacity-100' : 'opacity-75'}
        hover:opacity-100 transition duration-200
        cursor-pointer
      `}
      onClick={onClick}
    >
      {isFilled 
        ? <FilledStarIcon />
        : <EmptyStarIcon />
      }
    </div>
  )
}