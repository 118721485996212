import React, { useState } from 'react';
import { AnimationFeature, ExitFeature, MotionConfig } from 'framer-motion';
import { GamesProvider } from '../context/GamesContext';
import { TranslationsProvider } from '../context/TranslationsContext';
import { AccountProvider } from '../context/UserAccountContext';
import { HistoryContext, HistoryProvider } from '../context/HistoryContext';
import { WinnersContext, WinnersProvider } from '../context/WinnersContext';
import { ScrollProvider } from '../context/ScrollContext';
import { Container } from './Container';
import { ModalWrapper } from './ModalWrapper';
import { Games } from './Games';
import { Header } from './Header';
import { buildApi } from '../api';
import QueryString from 'query-string';
import { TimezoneProvider } from '../context/TimezoneContext';
import { DisclaimerContext, DisclaimerProvider } from '../context/DisclaimerContext';
import { BannerContext, BannerProvider } from '../context/BannerContext';
import BannerModal from './BannerModal';

const WinnersModal = React.lazy(() => import('./WinnersModal'));
const GameHistoryModal = React.lazy(() => import('./GameHistoryModal'));
const DisclaimerModal = React.lazy(() => import('./DisclaimerModal'));

function App() {
  const { token, lang, ref} = QueryString.parse(window.location.search)
  const api = buildApi(token, ref)

  const [error, setError] = useState()

  return (
    <AccountProvider api={api} setError={setError}>
      <DisclaimerProvider>
        <BannerProvider>
          <GamesProvider api={api}>
            <HistoryProvider api={api}>
              <WinnersProvider api={api}>
                <TimezoneProvider api={api}>
                  <TranslationsProvider urlLocale={lang}>
                    <MotionConfig features={[AnimationFeature, ExitFeature]}>
                      <Container error={error}>
                        <ModalWrapper context={WinnersContext}>
                          <WinnersModal />
                        </ModalWrapper>
                        <ModalWrapper context={HistoryContext}>
                          <GameHistoryModal />
                        </ModalWrapper>
                        <ModalWrapper context={DisclaimerContext}>
                          <DisclaimerModal />
                        </ModalWrapper>
                        <ModalWrapper context={BannerContext}>
                          <BannerModal />
                        </ModalWrapper>
                        <ScrollProvider>
                          <Header />
                          <Games />
                        </ScrollProvider>
                      </Container>
                    </MotionConfig>
                  </TranslationsProvider>
                </TimezoneProvider>
              </WinnersProvider>
            </HistoryProvider>
          </GamesProvider>
        </BannerProvider>
      </DisclaimerProvider>
    </AccountProvider>
  );
}

export default App;
