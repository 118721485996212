export const openWindow = ({
  url,
  name = 'game',
  height = 400,
  width = 400,
}) => {
  const y = window.top.outerHeight / 2 + window.top.screenY - height / 2;
  const x = window.top.outerWidth / 2 + window.top.screenX - width / 2;
  return window.open(
    url,
    name,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${y}, left=${x}`
  );
};

// setter
export const setStorageItem = (key, value) => {
  try {
    const data = JSON.stringify(value);
    localStorage.setItem(key, data);
  } catch (e) {
    console.log(e);
  }
};

// getter
export const getStorageItem = key => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch {
    return null;
  }
};

//
//
export const debounce = (callback, wait = 250) => {
  let timer;

  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback(...args), wait);
  };
};

//
export function uniqueBy(arr, fx) {
  const cb = typeof fx === 'function' ? fx : o => o[fx];

  return [
    ...arr
      .reduce((map, item) => {
        const key = item === null || item === undefined ? item : cb(item);

        if (!map.has(key)) {
          map.set(key, item);
        }

        return map;
      }, new Map())
      .values(),
  ];
}