import { Text } from './Text';

export const Button = ({
  children,
  onClick,
  className,
  hidden = false,
  primary = true,
}) => {
  if (hidden) return null

  return (
    <button
      onClick={onClick}
      className={`text-white inline-flex items-center uppercase rounded-full cursor-pointer focus:outline-none ${className} ${
        primary ? 'bg-primary' : 'bg-blue'
      }`}
    >
      <Text translate>{children}</Text>
    </button>
  );
};
