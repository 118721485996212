import { createContext, useCallback, useEffect, useState, useContext } from 'react';
import { debounce, openWindow, uniqueBy } from '../utils/utils';
import { gameSet, GameSetCode, GameSetsResponse, IGame, IGameSet, mergeGameSets } from '../utils/data';
import { DisclaimerContext } from './DisclaimerContext';
import { SearchResult } from '../components/SearchResults';
import { ApiInstance } from '../api';

interface GamesContextValue {
  findGameById: (gameId: number) => IGame | undefined;
  allGames?: IGame[];
  gameSets: IGameSet[];
  reloadLastPlayed: () => void;
  search: string;
  setSearch: (value: string) => void;
  searchResults: SearchResult[];
  launchGame: (game: IGame) => void;
  launchPromo: (url: string) => void;
  isStarred: (game: IGame) => boolean;
  starGame: (game: IGame) => void;
  unstarGame: (game: IGame) => void;
}

interface GamesProviderProps {
  api: ApiInstance;
}

export const GamesContext = createContext({} as GamesContextValue);

export const GamesProvider: React.FC<GamesProviderProps> = ({ api, children }) => {
  const { showDisclaimer } = useContext(DisclaimerContext);
  const [ gameSets, setGameSets ] = useState([] as IGameSet[]);

  useEffect(() => {
    let localVersion = getLocalGameVersion()
    let localList = getLocalGameList() as IGameSet[]
    setGameSets(localList)
    api.get('/games/version').then(version => {
      let apiVersion = parseInt(version.data.data)
      if (apiVersion !== localVersion || localList.length <= 0) {
        api.get('/games/list').then(list => {
          let apiList = list.data.data as IGameSet[]
          setGameSets(apiList)
          setLocalGameList(apiList)
          setLocalGameVersion(apiVersion)
          reloadFavourites(apiList)
        })
      } else {
        reloadFavourites(localList)
      }
    })
  }, [])

  const GAME_VERSION_KEY = "game-version"
  const GAME_LIST_KEY = "game-list"

  function reloadFavourites (sets: IGameSet[]) {
    sets = sets ? getLocalGameList() : sets
    api.get('/games/favourite').then(favs => {
      let apiFavs = favs.data.data as IGame[]
      sets.filter(set => set.code === GameSetCode.LastPlayed).forEach(set => set.games = apiFavs)
      setGameSets(sets)
    })
  }

  function getLocalGameVersion() {
    const value = window.localStorage.getItem(GAME_VERSION_KEY)
    return value ? parseInt(value) : 0
  }

  function setLocalGameVersion(version: Number) {
    window.localStorage.setItem(GAME_VERSION_KEY, String(version))
  }

  function getLocalGameList() {
    const value = window.localStorage.getItem(GAME_LIST_KEY)
    return value ? JSON.parse(value) : []
  }

  function setLocalGameList(list: IGameSet[]) {
    window.localStorage.setItem(GAME_LIST_KEY, JSON.stringify(list))
  }



  function setGameSetsFromLocalFile() {
    return import('../static/gamelist.json')
      .then(uiRes => {
        if (!uiRes.data) return Promise.reject()
        setGameSets(mergeGameSets(uiRes.data as IGameSet[], gameSetsFromLocalStorage()))
      })
  }

  function setGameSetsFromApi() {
    api.get('/games/sets').then(apiRes => {
      return setGameSets(apiRes.data.data);
    })
  }

  function findGameById(gameId: number) {
    return gameSet(gameSets, GameSetCode.AllGames)
      ?.games.find(game => game.id === gameId)
  }

  const [search, setSearch] = useState('');
  const [searchResults, setsearchResults] = useState([]);

  useEffect(() => {
    if (search.length) {
      debounceSearch(search);
    }
  }, [search]);

  const debounceSearch = useCallback(
    debounce((keyword: string) => searchGames(keyword)),
    []
  );

  const searchGames = (keyword: string) => {
    api.get('/games/search', {params: {key: keyword}}).then(res => {
      if (res.data.data) {
        setsearchResults(res.data.data)
      }
    })
  };


  async function launchGame(game: IGame) {
    await showDisclaimer()
    const gameUrl = api.referrer ?
      api.buildUrl('/launch', { gameId: game.id, key: api.token, ref: api.referrer }) :
      api.buildUrl('/launch', { gameId: game.id, key: api.token })
    openWindow({ url: gameUrl, height: 600, width: 800 });
    setTimeout(() => reloadLastPlayed(), 2000);
  };

  async function launchPromo(url: String) {
    openWindow({ url: url, height: 600, width: 800 });
  };

  function isStarred(game: IGame) {
    return !!gameSet(gameSets, GameSetCode.LastPlayed)
      ?.games.find(lastPlayedGame => lastPlayedGame.id === game.id)
  }

  function starGame(game: IGame) {
    addToLastPlayed(game)
    api.put(`/favourite?gameId=${game.id}`)
      .then(() => reloadFavourites(getLocalGameList()))
  }

  function unstarGame(game: IGame) {
    removeFromLastPlayed(game)
    api.delete(`/favourite?gameId=${game.id}`)
      .then(() => reloadFavourites(getLocalGameList()))
  }

  function reloadLastPlayed() {
    reloadFavourites(getLocalGameList())
    // api.get('/games/sets').then(updateLastPlayedFromResponse)
  }

  function addToLastPlayed(game: IGame) {
    updateLastPlayed(games => uniqueBy([ game, ...games ], (game: IGame) => game.id))
  }

  function removeFromLastPlayed(game: IGame) {
    updateLastPlayed(games => games.filter(aGame => aGame.id !== game.id))
  }

  function updateLastPlayed(callback: (games: IGame[]) => IGame[]) {
    const updated = gameSets.map(gameSet => 
      gameSet.code === GameSetCode.LastPlayed 
        ? { ...gameSet, games: callback(gameSet.games) } 
        : gameSet
    )
    setGameSets(updated)
  }

  function updateLastPlayedFromResponse(res: GameSetsResponse) {
    const lastPlayed = gameSet(res.data.data, GameSetCode.LastPlayed)
    updateLastPlayed(() => lastPlayed?.games || [])
    saveGameSetsToLocalStorage(lastPlayed ? [lastPlayed] : []);
  }

  const GAMESETS_KEY = "gamesets"

  function saveGameSetsToLocalStorage(gameSets: IGameSet[]) {
    window.localStorage.setItem(GAMESETS_KEY, JSON.stringify(gameSets))
  }

  function gameSetsFromLocalStorage() {
    const value = window.localStorage.getItem(GAMESETS_KEY)
    return value ? JSON.parse(value) : []
  }

  const value = {
    findGameById,
    allGames: gameSet(gameSets, GameSetCode.AllGames)?.games,
    gameSets,
    reloadLastPlayed,
    search,
    setSearch,
    searchResults,
    launchGame,
    launchPromo,
    isStarred,
    starGame,
    unstarGame
  };

  return (
    <GamesContext.Provider value={value}>{children}</GamesContext.Provider>
  );
};
