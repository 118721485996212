import React, { createContext, useState, useEffect } from 'react'
import { ApiInstance } from '../api'

export interface Timezone {
  name: string;
  utcOffset: string;
}

interface TimezoneContextValue {
  timezone?: Timezone;
}

interface TimezoneProviderProps {
  api: ApiInstance;
}

export const TimezoneContext = createContext({} as TimezoneContextValue)

export const TimezoneProvider: React.FC<TimezoneProviderProps> = ({ api, children }) => {
  const [timezone, setTimezone] = useState<Timezone | undefined>()

  useEffect(() => {
    api.get('/games/sets').then(res => {
      const name = res.data.meta.time_zone
      const match = /(?<sign>[+-])(?<hour>\d)/g.exec(name)
      const sign = match?.groups?.sign || '+'
      const hour = match?.groups?.hour || '0'
      const utcOffset = `${sign}0${hour}:00`
      setTimezone({ name, utcOffset })
    })
  }, [])

  const value = {
    timezone
  }

  return (
    <TimezoneContext.Provider value={value}>{children}</TimezoneContext.Provider>
  )
}
