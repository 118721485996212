import React, { createContext, useState, useRef } from 'react'


interface DisclaimerContextValue {
  showDisclaimer: () => void;
  opened: boolean;
  resolve: () => void;
  reject: () => void;
}

export const DisclaimerContext = createContext({} as DisclaimerContextValue)

const LOCAL_STORAGE_KEY = 'disclaimerLastShown'
const DISCLAIMER_COOLDOWN = 604800000 // one week

function lastShown() {
  const value = window.localStorage.getItem(LOCAL_STORAGE_KEY)
  return value ? parseInt(value) : undefined
}

function setLastShown(timestamp: number) {
  window.localStorage.setItem(LOCAL_STORAGE_KEY, timestamp.toString())
}

function isDisclaimerSupressed() {
  return lastShown() && (Date.now() - lastShown()! < DISCLAIMER_COOLDOWN)
}

export const DisclaimerProvider: React.FC = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false)
  const resolvePromise = useRef<() => void>();
  const rejectPromise = useRef<() => void>();

  function showDisclaimer() {
    return new Promise<void>((resolve, reject) => {
      resolvePromise.current = resolve
      rejectPromise.current = reject

      if (isDisclaimerSupressed()) {
        return resolvePromise.current()
      }
  
      setIsVisible(true)
    })
  }

  function resolve() {
    setIsVisible(false)
    resolvePromise.current?.()
    setLastShown(Date.now())
  }

  function reject() {
    setIsVisible(false)
    rejectPromise.current?.()
  }

  const value = {
    showDisclaimer,
    opened: isVisible,
    resolve,
    reject
  }

  return (
    <DisclaimerContext.Provider value={value}>{children}</DisclaimerContext.Provider>
  )
}
