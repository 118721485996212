import { createContext, useEffect, useState } from 'react';
import QueryString from 'query-string';
import { CHECK_NEW_WINNER_INTERVAL, WINNER_PAGE_SIZE } from '../config';
import { WinnerFeedMode } from '../utils/data';

export const WinnersContext = createContext();

export const WinnersProvider = ({ api, children }) => {
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [winners, setWinners] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [noData, setNoData] = useState(false);
  const [mode, setMode] = useState(WinnerFeedMode.Live);

  const fetchWinners = async () => {
    try {
      setLoading(true);

      await api
        .get("/feed/winner?" + QueryString.stringify({ mode }))
        .then(({ data }) => {
          const winnerList = data.data || [];
          const visiblePortionOfWinnersList = winnerList.slice(0, WINNER_PAGE_SIZE * pageIndex)

          setNoData(visiblePortionOfWinnersList.length === winnerList.length);
          
          setWinners(visiblePortionOfWinnersList);
        });
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const loadMore = () => {
    setPageIndex(index => index + 1);
  };

  useEffect(fetchWinners, [mode, pageIndex]);

  useEffect(() => {
    setPageIndex(1)
    setWinners([])
  }, [mode]);


  const handleClose = () => {
    setOpened(false);
    setPageIndex(1);
  };


  useEffect(() => {
    if (opened) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [opened]);


  const [lastWinnerId, setLastWinnerId] = useState(null);

  async function checkNewWinner() {
    const res = await api.get('/feed/winner');
    const winners = res.data?.data;

    const currentWinnerId = winners[0]?.id;

    if (lastWinnerId) {
      if (currentWinnerId !== lastWinnerId) {
        setLastWinnerId(currentWinnerId);
        const { playWinningAnimation } = await import('../utils/animation');
        playWinningAnimation();
      }
    } else {
      setLastWinnerId(currentWinnerId);
    };
  };

  let interval;

  useEffect(() => {
    interval = setInterval(checkNewWinner, CHECK_NEW_WINNER_INTERVAL);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastWinnerId]);


  const value = {
    open: () => setOpened(true),
    close: () => handleClose(),
    toggle: () => setOpened(state => !state),
    opened,
    loading,
    winners,
    noData,
    mode,
    setMode,
    fetchWinners,
    checkNewWinner,
    loadMore,
  };

  return (
    <WinnersContext.Provider value={value}>{children}</WinnersContext.Provider>
  );
};
