import React, { useContext } from 'react';
import { IGameSet, GameSetSource, GameSetType, Locale } from '../utils/data';
import { GameCarousel } from './GameCarousel';
import { GameGrid } from './GameGrid';
import { Hero } from './Hero';
import { TranslationsContext } from '../context/TranslationsContext';
import { TagCarousel } from './TagCarousel';

interface GameSetProps {
  gameSet: IGameSet;
}

export const GameSet: React.FC<GameSetProps> = ({ gameSet }) => {
  const { locale } = useContext(TranslationsContext);
  const { title, type, source, games, tags } = gameSet;

  if (type === GameSetType.Hero) return <Hero game={games[0]} />

  return (games?.length > 0 || tags?.length > 0) ? (
    <div className="w-full mx-auto mt-4 sm:mt-12">
      <p className="px-4 sm:px-10 mt-4 mb-2 md:mb-4 text-2xl md:text-3xl lg:text-4xl font-bold text-white capitalize">
        {title[locale as Locale]}
      </p>
      
      { type === GameSetType.Slider && source !== GameSetSource.Tag && <GameCarousel games={games} /> }
      { type === GameSetType.Slider && source === GameSetSource.Tag && <TagCarousel tags={tags} /> }
      { type === GameSetType.Grid && <GameGrid items={games} /> }
    </div>
  ) : null;
};
