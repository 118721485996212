import { Text } from './Text'

export const ErrorMessage = ({ error }) => (
  <div className="mt-16 mx-12 text-white space-y-4">
    <div className="text-4xl leading-none">
      <Text>We couldn't log you in</Text>
    </div>
    <div className="text-xl font-normal opacity-75">
      <Text>Please try again. If the problem persists, contact support.</Text>
    </div>
  </div>
)