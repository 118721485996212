export const Divider = ({ orientation, className }) => {
  return orientation === 'horizontal' ? (
    <hr
      className={`border-b border-t-0 border-gray-600 border-solid ${className}`}
    />
  ) : (
    <div
      className={`box-content h-12 md:h-16 ml-3 md:mx-4 border-l border-gray-600 border-opacity-35 ${className}`}
    />
  );
};
