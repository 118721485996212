import { useContext } from 'react';
import { GamesContext } from '../context/GamesContext';
import { TranslationsContext } from '../context/TranslationsContext';
import { DesktopPromo } from './DesktopPromo';
import { MobilePromo } from './MobilePromo';
import { promoImage, promoImageAlt } from '../utils/data';

export const Promo = () => {
  const { launchPromo } = useContext(GamesContext);
  const { locale } = useContext(TranslationsContext);
  function onClick() {
    launchPromo(`https://info.suerteinfo.com/${locale}-tnc.html`)
  }
  const image = promoImage(locale)
  const imageAlt = promoImageAlt(locale)

  return (
    <>
      <DesktopPromo {...{ image, imageAlt, onClick }} />
      <MobilePromo {...{ image, imageAlt, onClick }} />
    </>
  );
};
