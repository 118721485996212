import { Icon } from './Icon';

export const ButtonSettings = ({ className = '', onClick, isPanelOpen }) => {
  return (
    <button
      type="button"
      className={`focus:outline-none flex w-14 md:w-12 h-12 items-center justify-center text-white ${className}`}
      onClick={onClick}
    >
      <Icon
        name={isPanelOpen ? 'close' : 'user'}
        className="w-6 md:w-7"
      />
    </button>
  );
};
