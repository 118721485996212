import { useContext, useEffect } from 'react';
import { UserAccountContext } from '../context/UserAccountContext';
import { Amount } from './Amount';
import { Text } from './Text';

export const Balance = () => {
  const { data, fetchAccount } = useContext(UserAccountContext)

  useEffect(() => {
    const interval = setInterval(() => {
      fetchAccount()
    }, 1000 * 60)
    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="flex items-center justify-between space-x-4 py-1 md:py-0">
      <div className="text-right">
        <span className="text-base md:font-lg font-medium text-primary">
          <Text translate>Balance</Text>
        </span>
        <div>
          <Amount amount={data?.balance} withCurrencySymbol={true} />
        </div>
      </div>
    </div>
  );
};
