import { AnimatePresence, m as motion } from 'framer-motion';
import { useContext } from 'react';
import { HistoryContext } from '../context/HistoryContext';
import {
  localeNames,
  supportedLocales,
  TranslationsContext,
} from '../context/TranslationsContext';
import { Button } from './Button';
import { Divider } from './Divider';
import { HistoryTable } from './HistoryTable';
import { Icon } from './Icon';
import { Text } from './Text';

export const SettingsPanel = ({ isPanelOpen, onClose }) => {
  const { open, loading, history } = useContext(HistoryContext);
  const { locale, setLocale } = useContext(TranslationsContext);

  function onClickViewAll() {
    open()
    setTimeout(onClose, 200)
  }

  return (
    <AnimatePresence>
      {isPanelOpen && (
        <motion.div
          initial={{ opacity: 0, y: 15 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 15 }}
          className="absolute z-40 flex items-center justify-center w-full max-w-screen-sm mt-1 top-15 md:top-20 md:right-3"
        >
          <div className="fixed inset-0" onClick={onClose} />
          <div className="relative w-full p-5 bg-black rounded-modal shadow-modal">
            <div className="relative flex items-center justify-between px-3 mt-3">
              <span className="pt-1 text-xl font-light text-white uppercase">
                <Text translate>Select Language</Text>
              </span>

              <div className="relative pl-6 pr-4 group">
                <div className="flex items-center w-28">
                  <img
                    src={`/images/flag-${locale}.png`}
                    alt=""
                    className="h-5 rounded-sm w-9"
                  />
                  <span className="pt-1 mx-2 text-xl font-light leading-none text-white uppercase">
                    {localeNames[locale]}
                  </span>
                  <Icon name="arrow-down" className="w-6" />
                </div>

                <div className="absolute inset-x-0 hidden pt-2 space-y-1 group-hover:block">
                  <div className="p-4 rounded-lg bg-secondary">
                    {supportedLocales.map(supportedLocale => (
                      <button
                        key={supportedLocale}
                        onClick={() => setLocale(supportedLocale)}
                        className="flex items-center px-4 py-3 rounded-lg hover:bg-tertiary focus:outline-none"
                      >
                        <img
                          src={`/images/flag-${supportedLocale}.png`}
                          alt={localeNames[supportedLocale]}
                          className="h-5 rounded-sm w-9"
                        />
                        <span className="pt-1 mx-2 text-xl font-light leading-none text-white uppercase">
                          {localeNames[supportedLocale]}
                        </span>
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <Divider orientation="horizontal" className="mx-5 mt-12 mb-10" />

            <h2 className="font-bold leading-none text-center text-white uppercase text-28">
              <Text translate>Game history</Text>
            </h2>

            {loading && history?.length === 0 && (
              <div className="p-4 pb-3 mt-4 text-sm font-bold text-center text-white uppercase rounded-lg bg-tertiary text-opacity-30 ">
                <Text>Loading...</Text>
              </div>
            )}

            <div
              className={`relative px-5 mt-4 rounded-lg bg-tertiary overflow-auto history-table-small ${
                history?.length === 0 ? 'hidden' : ''
              }`}
            >
              <HistoryTable modal={false} />
            </div>

            <div className="flex justify-center mt-6">
              <Button className="h-12 px-12 text-xl" onClick={onClickViewAll}>
                View All
              </Button>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
