import { useEffect, useRef, useState } from "react";

type UseIntersectReturn = [React.Dispatch<React.SetStateAction<HTMLElement | null>>, boolean, number];

export default function useIntersect(options?: IntersectionObserverInit): UseIntersectReturn {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [intersectionRatio, setIntersectionRatio] = useState(0);
  const [node, setNode] = useState<HTMLElement | null>(null);

  const observer = useRef<IntersectionObserver | null>(null);
  
  useEffect(() => {
    observer.current?.disconnect();

    observer.current = new window.IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
        setIntersectionRatio(entry.intersectionRatio);
      },
      options
    );

    const { current: currentObserver } = observer;
    currentObserver.disconnect();

    if (node) currentObserver.observe(node!);

    return () => currentObserver.disconnect();
  }, [node]);

  return [setNode, isIntersecting, intersectionRatio];
};
