import { useContext } from 'react';
import { GamesContext } from '../context/GamesContext';
import { TranslationsContext } from '../context/TranslationsContext';
import { DesktopHero } from './DesktopHero';
import { MobileHero } from './MobileHero';
import { gameImage } from '../utils/data';

export const Hero = ({ game }) => {
  const { launchGame } = useContext(GamesContext);
  const { locale } = useContext(TranslationsContext);

  function onClick() {
    launchGame(game)
  }

  const image = gameImage(game, locale)
  const imageAlt = game.name

  return (
    <>
      <DesktopHero {...{ image, imageAlt, onClick }} />
      <MobileHero {...{ image, imageAlt, onClick }} />
    </>
  );
};
