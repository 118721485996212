import moment from "moment";

export function formatDate(date) {
  return moment(date).format("YYYY-MM-DD");
};

export function formatTime(date) {
  return moment(date).format("HH:mm");
};

export function formatFullDate(date) {
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
};