import axios, { AxiosInstance } from 'axios';
import QueryString from 'query-string';
import { API_URL } from './config';

export interface ApiInstance extends AxiosInstance {
  buildUrl: (path: string, params: any) => string;
  token: string;
  referrer: string | null;
}

export function buildApi(token: string, referrer: string | null) {
  const instance = axios.create({
    baseURL: API_URL,
    headers: { "Authorization": `Bearer ${token}` }
  })

  function buildUrl(path: string, params: any) {
    return API_URL + path + '?' + QueryString.stringify(params)
  }

  return { ...instance, buildUrl, token, referrer } as ApiInstance
}