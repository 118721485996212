import { IGame, ITag } from '../utils/data';
import { GameGrid } from './GameGrid';


export enum SearchResultType {
  Tag = "tag",
  Game = "game"
}

export interface SearchResult {
  type: SearchResultType;
  data: IGame | ITag;
}


interface SearchResultsProps {
  searchResults: SearchResult[];
}

export function SearchResults({ searchResults }: SearchResultsProps) {
  return (
    <div className="pt-24 md:pt-15">
      <GameGrid items={searchResults} loadMoreButton={false} smCols="grid-cols-1" />
    </div>
  )
}