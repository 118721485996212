import { useEffect, useState } from 'react';
import useResizeObserver from 'use-resize-observer';

import { Text } from './Text';

export const Badge = ({ label }) => {
  const { ref, width = 420, height = 236 } = useResizeObserver();
  const [fontSize, setFontSize] = useState(20);
  const [marginLeft, setMarginLeft] = useState(20);
  const [marginTop, setMarginTop] = useState(20);

  let color = `bg-badge-default`;

  if (label === 'coming-soon') {
    color = 'bg-coming-soon';
  }
  if (label === 'new') {
    color = 'bg-new';
  }
  if (label === 'jackpot') {
    color = 'bg-jackpot';
  }
  if (label === 'popular') {
    color = 'bg-popular';
  }

  useEffect(() => {
    const fz = Math.floor(width * 0.05);
    setFontSize(fz >= 40 ? 40 : fz);
    const ml = Math.floor((width * 1.20));
    setMarginLeft(ml);
  }, [width]);

  useEffect(() => {
    const mt = Math.floor(height * -0.6);
    setMarginTop(mt)
  }, [height]);

  return (
    <div
      ref={ref}
      className={`
      badge absolute whitespace-no-wrap
      w-2/3 h-2/6
      text-white uppercase
      transform -translate-x-1/2 -translate-y-1/2 rotate-45
      flex items-end justify-center py-2
      z-10 scale-1/2 
      ${color}`
      }
      style={{ marginLeft: marginLeft, marginTop: marginTop, fontSize: fontSize }}
    >
      <Text translate>{label}</Text>
    </div>
  );
};
