import { useState, useEffect, createContext } from 'react';

export const UserAccountContext = createContext();

export const AccountProvider = ({ api, children, setError }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAccount = async () => {
    try {
      setLoading(true);
      await api.get('/account').then(res => {
        setData({
          account: res.data?.data?.[0]?.name,
          balance: res.data?.data?.[0]?.balance,
          currency: res.data?.meta?.currency,
        });
      }).catch(error => {
        if (process.env.NODE_ENV === 'production') {
          setError(error)
        }
      })
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const value = {
    data,
    loading,
    fetchAccount,
  };

  return (
    <UserAccountContext.Provider value={value}>
      {children}
    </UserAccountContext.Provider>
  );
};
