import React, { useContext, useState } from 'react';
import { m as motion } from 'framer-motion';
import { ButtonClose } from './ButtonClose';
import { Text } from './Text';
import { BannerContext } from '../context/BannerContext';
import { bannerImage, bannerImageAlt, bannerImageLink } from '../utils/data';
import { TranslationsContext } from '../context/TranslationsContext';
import { LazyImage } from './LazyImage';

const backdrop = {
  visible: { opacity: 1, transition: { duration: 0.5 } },
  hidden: { opacity: 0, transition: { delay: 0.2, duration: 0.5 } },
};

const modal = {
  visible: { y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.2 } },
  hidden: { y: -30, opacity: 0, transition: { duration: 0.2 } },
};

const BannerModal = () => {
  const [hidden, setHidden] = useState(false);
  const { reject, resolve } = useContext(BannerContext);
  const { locale } = useContext(TranslationsContext);
  const image = bannerImage(locale)
  const imageAlt = bannerImageAlt(locale)
  const imageSizeMap = [
    ["(min-width: 1280px)", "lg"],
    ["", "md"]
  ]

  return (
    <motion.div className="fixed inset-0 z-40 block">
      <div className="fixed inset-0 lg:pt-28 pb-20">
        <motion.div
          onClick={() => reject(hidden)}
          variants={backdrop}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className="fixed inset-0 bg-blur"
        />
        <motion.div
          variants={modal}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className="relative z-10 w-full sm:w-3/4 lg:w-2/3 p-8 m-auto mt-10 rounded-modal"
        >
          <div className="relative px-10 text-center">
            <a href={bannerImageLink()} target="_blank">
              <LazyImage
                className="w-full aspect-h-9 aspect-w-16 rounded overflow-hidden"
                imageClasses="w-full rounded"
                hash={image.blurHash}
                alt={imageAlt}
                imageSizes={image.hor}
                sizeMap={imageSizeMap}
                fallbackSize="md"
                resolution={40}
                hasBlurhash={true}
              />
            </a>
            <h2 className="font-bold leading-none text-white uppercase mx-8 text-28">
              <Text translate></Text>
            </h2>
            <ButtonClose
              onClick={() => resolve(hidden)}
              className="absolute top-0 right-0 h-6 mr-1 mt-1 text-white hover:opacity-75"
            />
          </div>

          <div className="mt-4 mb-4 flex justify-center items-center gap-x-2">
            <input
              id="hide"
              name="hide"
              type="checkbox"
              className="h-5 w-5 rounded"
              checked={hidden}
              onChange={() => setHidden(!hidden)}
            />
            <label htmlFor="hide" className="text-white text-lg mt-1">
              <Text translate>Do not show again.</Text>
            </label>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default BannerModal;
