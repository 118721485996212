import React, { CSSProperties, useState } from 'react';
import { BlurhashCanvas } from 'react-blurhash';
import { ImageSize, ImageSizes, Picture, SizeMap } from './Picture';
import useIntersect from '../utils/useIntersect';
import { gameRibbon } from '../utils/data';
import { Badge } from './Badge';


interface LazyImageProps {
  imageSizes: ImageSizes;
  sizeMap: SizeMap;
  fallbackSize: ImageSize;
  hash: string;
  resolution?: number;
  retinaSupport?: boolean;
  hasBlurhash?: boolean;
  className?: string;
  imageClasses?: string;
  canvasStyle?: CSSProperties;
  alt?: string;
  width?: number;
  height?: number;
  badge?: string;
}

export const LazyImage: React.FC<LazyImageProps> = ({
  imageSizes,
  sizeMap,
  fallbackSize,
  hash,
  resolution = 2,
  retinaSupport = false,
  hasBlurhash = true,
  className,
  imageClasses,
  canvasStyle = {},
  alt,
  width,
  height,
  badge
}) => {
  const [loaded, setLoaded] = useState(false);
  const [blurVisible, setBlurVisible] = useState(true);
  const [ref, isVisible] = useIntersect({ rootMargin: "200px" });

  function onLoad() {
    setLoaded(true);
    setBlurVisible(false);
  }
  
  function onUnload() {
    setLoaded(false);
    setBlurVisible(true);
  }

  return (
    <div className={`${className} relative`} ref={ref} style={canvasStyle}>
      { (badge) && <Badge label={badge} /> }
      {isVisible && hasBlurhash && blurVisible && (
        <BlurhashCanvas
          className="w-full h-full"
          style={canvasStyle}
          hash={hash}
          width={resolution}
          height={resolution}
          punch={1}
        />
      )}

      {(isVisible || loaded) && (
        <Picture
          className={`
            ${blurVisible && "absolute top-0"} 
            ${!loaded && "opacity-0"}
            ${imageClasses}
          `}
          {...{ imageSizes, sizeMap, fallbackSize, onLoad, onUnload, alt, width, height, retinaSupport }}
        />
      )}
    </div>
  );
};
