import { Button } from './Button';
import { LazyImage } from './LazyImage';
import { Text } from './Text';

export const DesktopHero = ({ image, imageAlt, onClick }) => {
  const imageSizeMap = [
    ["(min-width: 1280px)", "lg"],
    ["", "md"]
  ]

  return (
    <div className="hidden lg:flex bg-dark relative overflow-hidden">
      <LazyImage
        className="w-2/3"
        canvasStyle={{height: "calc(min(66.6vw, 1280px) * 9 / 16)"}}
        imageClasses="object-left w-full"
        hash={image.blurHash}
        alt={imageAlt}
        imageSizes={image.hor}
        sizeMap={imageSizeMap}
        fallbackSize="md"
        resolution={40}
        hasBlurhash={false}
      />

      <div 
        className="w-1/3 -ml-2/12 z-10"
        style={{background: "linear-gradient(to left, #010103, #010103 60%, transparent)"}}
      ></div>

      <div className="absolute right-8 w-1/3 h-full p-9 flex items-center text-white z-20 _slider-content">
        <div>
          <h2 className="font-bold leading-none uppercase lg:text-5xl xl:text-6xl 2xl:text-7xl">
            <Text translate>i18n.set.hero.title</Text>
          </h2>

          <p className="mt-6 xl:mt-8 hidden lg:text-xl font-light leading-tight sm:block xl:text-3xl">
            <Text translate>i18n.set.hero.sub_title_1</Text>
          </p>
          <p className="mt-3 xl:mt-4 hidden lg:text-xl font-light leading-tight sm:block xl:text-3xl">
            <Text translate>i18n.set.hero.sub_title_2</Text>
          </p>

          <div className="mt-7 xl:mt-9">
            <Button className="text-xl pointer-events-auto h-14 px-14"
              onClick={onClick}>Play now</Button>
          </div>
        </div>
      </div>
    </div>
  );
};
