import { ErrorMessage } from './ErrorMessage'

export function Container({ error, children }) {
  return (
    <div 
      style={{maxWidth: "1920px", margin: "auto"}} 
      className="max-h-screen flex flex-col"
    >
      {error ? <ErrorMessage error={error} /> : children}
    </div>
  )
}