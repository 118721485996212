import { createContext, useEffect, useState } from 'react';
import moment from "moment";
import "moment/locale/ko";

import { DEFAULT_LOCALE } from '../config';

export const TranslationsContext = createContext();

export const localeNames = {
  en: 'ENG',
  kr: 'KOR',
};

export const supportedLocales = Object.keys(localeNames);

const standardLocale = {
  'en': 'en',
  'kr': 'ko'
};

function validLocale(locale) {
  return supportedLocales.includes(locale) && locale;
}

function localStorage() {
  return window.localStorage.getItem('locale');
}

function setLocalStorage(locale) {
  window.localStorage.setItem('locale', locale);
}

export const TranslationsProvider = ({ children, urlLocale }) => {
  const [messages, setMessages] = useState({});
  const [locale, setLocale] = useState(() =>
    localStorage() || validLocale(urlLocale) || DEFAULT_LOCALE
  );

  useEffect(() => {
    fetchMessages(locale);
    moment.locale(standardLocale[locale]);
  }, [locale]);

  async function fetchMessages(locale) {
    const standardLocaleCode = standardLocale[locale];
    const translations = await import(`../i18n/${standardLocaleCode}.json`);
    setMessages(translations.default);
  };

  const value = {
    locale,
    setLocale: (locale) => {
      setLocale(locale)
      setLocalStorage(locale)
    },
    messages
  };

  return (
    <TranslationsContext.Provider value={value}>
      {children}
    </TranslationsContext.Provider>
  );
};
