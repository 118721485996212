import React, { useContext, useEffect, useState } from 'react';
import { GamesContext } from '../context/GamesContext';
import { SearchResults } from './SearchResults';
import { GameSet } from './GameSet';
import { ScrollContext } from '../context/ScrollContext';
import { Hero } from './Hero';
import { Promo } from './Promo';
import { GameSetType } from '../utils/data';

export const Games = () => {
  const { gameSets, reloadLastPlayed, search, searchResults } = useContext(GamesContext);
  const { scrollTop } = useContext(ScrollContext);
  const [ isLastPlayedLoaded, setIsLastPlayedLoaded ] = useState(false);

  useEffect(() => {
    // if (!isLastPlayedLoaded && scrollTop > 10) {
    //   reloadLastPlayed();
    //   setIsLastPlayedLoaded(true);
    // }
  }, [ scrollTop ])

  if (!gameSets.length) return null

  return (
    <div className="compensate-header">
      {search.length ? (
        <SearchResults searchResults={searchResults} />
      ) : (
        <>
          <Promo />
          {
            gameSets.filter(gameSet => gameSet.type !== GameSetType.Hero).map(gameSet =>
              <GameSet gameSet={gameSet} key={gameSet.code} />
            )
          }
          {/*{*/}
          {/*  gameSets.map(gameSet =>*/}
          {/*    <GameSet gameSet={gameSet} key={gameSet.code} />*/}
          {/*  )*/}
          {/*}*/}
        </>
      )}
    </div>
  );
};
