import { useContext } from 'react';

import { TranslationsContext } from '../context/TranslationsContext.js';

export const Text = ({ children, translate = true }) => {
  const { messages } = useContext(TranslationsContext);
  const content = translate && children in messages ? messages[children] : children;

  return <span 
    className="leading-none inline-flex-col"
    dangerouslySetInnerHTML={{__html: content}}
  ></span>;
};
