import React, { createContext, useState, useRef, useEffect } from 'react';
import { bannerImageName, isBannerEnabled } from '../utils/data';


interface BannerContextValue {
  opened: boolean;
  resolve: (doNotShowAgain: boolean) => void;
  reject: (doNotShowAgain: boolean) => void;
}

export const BannerContext = createContext({} as BannerContextValue)

const LOCAL_STORAGE_KEY = 'hide_banner'

function hide() {
  const key = `${LOCAL_STORAGE_KEY}-${bannerImageName()}`
  window.localStorage.setItem(key, "true")
}

function isHidden() {
  const key = `${LOCAL_STORAGE_KEY}-${bannerImageName()}`
  const hidden = window.localStorage.getItem(key)
  return !!hidden && hidden === "true"
}

export const BannerProvider: React.FC = ({ children }) => {
  const [inited, setInited] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const resolvePromise = useRef<() => void>();
  const rejectPromise = useRef<() => void>();

  useEffect(() => {
    if (!inited) {
      const hidden = isHidden();
      const hasBanner = isBannerEnabled()
      if (!hidden && !isVisible && hasBanner) {
        setIsVisible(true);
      }
      setInited(true);
    }
  }, [inited])

  function resolve(doNotShowAgain: boolean) {
    if (doNotShowAgain) hide();
    setIsVisible(false)
    resolvePromise.current?.()
  }

  function reject(doNotShowAgain: boolean) {
    if (doNotShowAgain) hide();
    setIsVisible(false)
    rejectPromise.current?.()
  }

  const value = {
    opened: isVisible,
    resolve,
    reject
  }

  return (
    <BannerContext.Provider value={value}>{children}</BannerContext.Provider>
  )
}
