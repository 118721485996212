import { useContext, useEffect } from 'react';
import { GamesContext } from '../context/GamesContext';
import { TranslationsContext } from '../context/TranslationsContext';
import { ButtonClose } from './ButtonClose';
import { Icon } from './Icon';

export const Search = ({ className }) => {
  const { search, setSearch } = useContext(GamesContext);

  useEffect(() => {
    window.scroll(0, 0)
  }, [search])

  return (
    <form onSubmit={e => e.preventDefault()} className={className}>
      <SearchField
        search={search}
        setSearch={setSearch}
      />
    </form>
  );
};

const SearchField = ({ search, setSearch, className }) => {
  const { messages } = useContext(TranslationsContext);

  return (
    <div
      className={`relative flex items-center h-12 pl-3 pr-10 rounded bg-search ${className}`}
    >
      <Icon name="search" className="flex-shrink-0 w-auto h-7" />

      <input
        className="flex-1 text-lg font-light text-center text-white placeholder-white bg-transparent placeholder-opacity-40 focus:outline-none"
        type="text"
        value={search}
        placeholder={messages['Search games']}
        onChange={e => setSearch(e.target.value)}
      />

      {search.length > 0 && (
        <div className="absolute top-0 bottom-0 right-0 flex items-center pr-4">
          <ButtonClose className="h-5 text-white" onClick={() => setSearch('')} />
        </div>
      )}
    </div>
  );
};
