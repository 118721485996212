import { useContext, useEffect, useState } from 'react';
import { HistoryContext } from '../context/HistoryContext';
import { GamesContext } from '../context/GamesContext';
import { TimezoneContext } from '../context/TimezoneContext';
import { formatDate, formatTime } from '../utils/time';
import { Table, TableCell, TableHeading } from './Table';
import { Amount } from './Amount';

export const HistoryTable = ({ modal }) => {
  const { history, fetchHistory } = useContext(HistoryContext);
  const { findGameById } = useContext(GamesContext);
  const { timezone } = useContext(TimezoneContext);

  const [transactions, setTransactions] = useState([]);

  useEffect(fetchHistory, []);

  useEffect(() => {
    setTransactions(modal ? history : history.slice(0, 6));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  function transactionGame(transaction) {
    const gameId = transaction?.meta_data?.item_id;
    return findGameById(gameId);
  }

  return (
    <Table className="w-full">
      <thead>
        <tr>
          <TableHeading first>Game</TableHeading>
          <TableHeading>Wager</TableHeading>
          <TableHeading>Payout</TableHeading>
          {modal && <TableHeading>Transaction ID</TableHeading>}
          <TableHeading last>Time</TableHeading>
        </tr>
      </thead>
      <tbody>
        {transactions.map(transaction => (
          <tr key={transaction.id}>
            <TableCell first className="font-bold" cellClassName="py-5">
              {transactionGame(transaction)?.name || '---'}
            </TableCell>
            <TableCell className="inline-flex items-center text-lg font-semibold" paddingX="px-2 sm:px-4">
              <Amount amount={transaction.sum_of_wager} precision={2} />
            </TableCell>
            <TableCell className="inline-flex items-center text-lg font-semibold" paddingX="px-2 sm:px-4">
              <Amount amount={transaction.sum_of_payout} precision={2} />
            </TableCell>
            {modal && (
              <TableCell className="text-center">{transaction.id}</TableCell>
            )}
            <TableCell last className="text-opacity-35 leading-tight">
              {formatDate(transaction.close_time)}
              <br />
              {formatTime(transaction.close_time)} {timezone?.name}
            </TableCell>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};