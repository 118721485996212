interface AmountProps {
  amount: number;
  withCurrencySymbol?: boolean;
}

export function Amount({ amount, withCurrencySymbol = false }: AmountProps) {
  const currencyFormatterOptions = withCurrencySymbol ? { style: 'currency', currency: 'KRW' } : {}
  const currencyFormatter = Intl.NumberFormat('ko', currencyFormatterOptions)
  return (
    <span className="text-lg font-semibold text-white m-0">
      {amount ? currencyFormatter.format(amount) : '---'}
    </span>
  )
}

