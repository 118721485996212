import { useState, useEffect, createContext } from 'react';
import QueryString from 'query-string';
import { formatFullDate } from '../utils/time';
import moment from 'moment';

export const HistoryContext = createContext();

export const HistoryProvider = ({ api, children }) => {
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const [noData, setNoData] = useState(false);
  const [opened, setOpened] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);

  const fetchHistory = async (pageIndex = undefined) => {
    const since = formatFullDate(moment().subtract(7, 'days'))
    const to = formatFullDate(moment())

    const params = QueryString.stringify({
      pageIndex, since, to
    })

    try {
      setLoading(true);
      await api
        .get('/feed/transactionround?' + params)
        .then(({ data }) => {
          const historyList = data.data || [];

          setNoData(historyList.length <= 0);
          setHistory(history =>
            pageIndex ? [...history, ...historyList] : historyList
          );
        });
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const filterHistory = async (gameId, since, to) => {
    const params = QueryString.stringify({
      since, to, gameId
    })

    try {
      setLoading(true);
      await api
        .get('/feed/transactionround?' + params)
        .then(({ data }) => {
          const historyList = data.data || [];
          setNoData(historyList.length <= 0);
          setHistory(historyList);
        });
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const loadMore = () => {
    setPageIndex(index => index + 1);
  };

  useEffect(() => {
    if (pageIndex > 1) {
      fetchHistory(pageIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  useEffect(() => {
    if (opened) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [opened]);

  const handleClose = () => {
    setOpened(false);
    clearData(true);
  };

  const clearData = fromModal => {
    setPageIndex(1);

    if (fromModal) {
      setHistory(history => history.slice(0, 10));
    } else {
      setHistory([]);
    }
    setNoData(false);
  };

  const value = {
    noData,
    opened,
    history,
    loading,
    loadMore,
    clearData,
    fetchHistory,
    filterHistory,
    close: () => handleClose(),
    open: () => setOpened(true),
  };

  return (
    <HistoryContext.Provider value={value}>{children}</HistoryContext.Provider>
  );
};
