import { Icon } from './Icon';

export const ButtonClose = ({ onClick, className = '' }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`focus:outline-none h-4 ${className}`}
    >
      <Icon name="close" className="w-auto h-full" />
    </button>
  );
};
