import { ImageSizes } from '../components/Picture';

export enum WinnerFeedMode {
  Live = 'live',
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export enum GameSetCode {
  AllGames = 'all',
  LastPlayed = 'last_played',
  Hero = 'hero',
}

export enum GameSetType {
  Slider = 'slider',
  Grid = 'grid',
  Hero = 'hero',
}

export enum GameSetSource {
  Tag = 'tag',
}

export enum Locale {
  English = 'en',
  Korean = 'kr',
}

interface Localized<T> {
  [Locale.English]: T;
  [Locale.Korean]: T;
}

export interface IGameSet {
  title: Localized<string>;
  code: GameSetCode;
  type: GameSetType;
  source: GameSetSource;
  name: string;
  games: IGame[];
  tags: ITag[];
}

export interface IGame {
  title: Localized<string>;
  image: Localized<GameImage>;
  name: string;
  id: number;
  ribbon: IRibbon;
}

export interface ITag {
  title: Localized<string>;
  image: Localized<ImageSizes>;
  name: string;
  blurHash: string;
}

interface GameImage {
  hor: ImageSizes;
  blurHash: string;
}

export interface IRibbon {
  id: number;
  text: Localized<string>;
}

export interface GameSetsResponse {
  data: {
    data: IGameSet[];
  };
}

export function gameSet(
  gameSets: IGameSet[],
  code: GameSetCode
): IGameSet | undefined {
  return gameSets.find(gameSet => gameSet.code === code);
}

export function mergeGameSets(
  base: IGameSet[],
  amendment: IGameSet[]
): IGameSet[] {
  return base.map(
    ({ code }) => gameSet(amendment, code) || gameSet(base, code)!
  );
}

const defaultBlurHash = 'ULFjM;iv01yE?^IBMx.9EmS1i_jKV|xtk8S3';

export function gameImage(game: IGame, locale: Locale) {
  const gameImage = game.image[locale];
  return {
    ...gameImage,
    blurHash: gameImage.blurHash || defaultBlurHash,
  };
}

export function gameRibbon(game: IGame, locale: Locale) {
  if (game.ribbon && game.ribbon.id) {
    return game.ribbon.text[locale];
  }
  return '';
}

const promoName = 'mg_games_2403_b1';
const promoTitle = 'MG New Games'
const promoImages = {
  en: {
    blurHash: 'UMM7MvD*x_PDPE%LxVozXViw$cwHCA$|tRW9',
    hor: {
      lg: {
        avif: `https://lobby-asset.gameatrium.com/resize_promo?key=${promoName}&size=lg&format=avif&language=en`,
        png: `https://lobby-asset.gameatrium.com/resize_promo?key=${promoName}&size=lg&format=png&language=en`,
        webp: `https://lobby-asset.gameatrium.com/resize_promo?key=${promoName}&size=lg&format=webp&language=en`,
      },
      md: {
        avif: `https://lobby-asset.gameatrium.com/resize_promo?key=${promoName}&size=md&format=avif&language=en`,
        png: `https://lobby-asset.gameatrium.com/resize_promo?key=${promoName}&size=md&format=png&language=en`,
        webp: `https://lobby-asset.gameatrium.com/resize_promo?key=${promoName}&size=md&format=webp&language=en`,
      },
      sm: {
        avif: `https://lobby-asset.gameatrium.com/resize_promo?key=${promoName}&size=sm&format=avif&language=en`,
        png: `https://lobby-asset.gameatrium.com/resize_promo?key=${promoName}&size=sm&format=png&language=en`,
        webp: `https://lobby-asset.gameatrium.com/resize_promo?key=${promoName}&size=sm&format=webp&language=en`,
      },
    },
  },
  kr: {
    blurHash: 'UOM7P+IVx^PCPEx[$xogT1a0$cwaGc$ztSWA',
    hor: {
      lg: {
        avif: `https://lobby-asset.gameatrium.com/resize_promo?key=${promoName}&size=lg&format=avif&language=kr`,
        png: `https://lobby-asset.gameatrium.com/resize_promo?key=${promoName}&size=lg&format=png&language=kr`,
        webp: `https://lobby-asset.gameatrium.com/resize_promo?key=${promoName}&size=lg&format=webp&language=kr`,
      },
      md: {
        avif: `https://lobby-asset.gameatrium.com/resize_promo?key=${promoName}&size=md&format=avif&language=kr`,
        png: `https://lobby-asset.gameatrium.com/resize_promo?key=${promoName}&size=md&format=png&language=kr`,
        webp: `https://lobby-asset.gameatrium.com/resize_promo?key=${promoName}&size=md&format=webp&language=kr`,
      },
      sm: {
        avif: `https://lobby-asset.gameatrium.com/resize_promo?key=${promoName}&size=sm&format=avif&language=kr`,
        png: `https://lobby-asset.gameatrium.com/resize_promo?key=${promoName}&size=sm&format=png&language=kr`,
        webp: `https://lobby-asset.gameatrium.com/resize_promo?key=${promoName}&size=sm&format=webp&language=kr`,
      },
    },
  },
};

export function promoImage(locale: Locale) {
  const image = promoImages[locale];
  return {
    ...image,
    blurHash: image.blurHash || defaultBlurHash,
  };
}

export function promoImageAlt(locale: Locale) {
  return promoTitle;
}

const bannerEnabled = false
const bannerName = 'mg_sept22_games';
const bannerTitle = 'MG Live Dealer'
const bannerLink = 'https://www.google.com'
const bannerImages = {
  en: {
    blurHash: 'UbH3BdK6IBv}yst3XBnmAersjEk7K8Rnn#WB',
    hor: {
      lg: {
        avif: `https://lobby-asset.gameatrium.com/resize_promo?key=${bannerName}&size=lg&format=avif&language=en`,
        png: `https://lobby-asset.gameatrium.com/resize_promo?key=${bannerName}&size=lg&format=png&language=en`,
        webp: `https://lobby-asset.gameatrium.com/resize_promo?key=${bannerName}&size=lg&format=webp&language=en`,
      },
      md: {
        avif: `https://lobby-asset.gameatrium.com/resize_promo?key=${bannerName}&size=md&format=avif&language=en`,
        png: `https://lobby-asset.gameatrium.com/resize_promo?key=${bannerName}&size=md&format=png&language=en`,
        webp: `https://lobby-asset.gameatrium.com/resize_promo?key=${bannerName}&size=md&format=webp&language=en`,
      },
      sm: {
        avif: `https://lobby-asset.gameatrium.com/resize_promo?key=${bannerName}&size=sm&format=avif&language=en`,
        png: `https://lobby-asset.gameatrium.com/resize_promo?key=${bannerName}&size=sm&format=png&language=en`,
        webp: `https://lobby-asset.gameatrium.com/resize_promo?key=${bannerName}&size=sm&format=webp&language=en`,
      },
    },
  },
  kr: {
    blurHash: 'UdG,9SK7Mev|yst4W@nkEnrraxk9K8Rnn#WB',
    hor: {
      lg: {
        avif: `https://lobby-asset.gameatrium.com/resize_promo?key=${bannerName}&size=lg&format=avif&language=kr`,
        png: `https://lobby-asset.gameatrium.com/resize_promo?key=${bannerName}&size=lg&format=png&language=kr`,
        webp: `https://lobby-asset.gameatrium.com/resize_promo?key=${bannerName}&size=lg&format=webp&language=kr`,
      },
      md: {
        avif: `https://lobby-asset.gameatrium.com/resize_promo?key=${bannerName}&size=md&format=avif&language=kr`,
        png: `https://lobby-asset.gameatrium.com/resize_promo?key=${bannerName}&size=md&format=png&language=kr`,
        webp: `https://lobby-asset.gameatrium.com/resize_promo?key=${bannerName}&size=md&format=webp&language=kr`,
      },
      sm: {
        avif: `https://lobby-asset.gameatrium.com/resize_promo?key=${bannerName}&size=sm&format=avif&language=kr`,
        png: `https://lobby-asset.gameatrium.com/resize_promo?key=${bannerName}&size=sm&format=png&language=kr`,
        webp: `https://lobby-asset.gameatrium.com/resize_promo?key=${bannerName}&size=sm&format=webp&language=kr`,
      },
    },
  },
};

export function bannerImage(locale: Locale) {
  const image = bannerImages[locale];
  return {
    ...image,
    blurHash: image.blurHash || defaultBlurHash,
  };
}

export function bannerImageName() {
  return bannerName;
}

export function bannerImageAlt(locale: Locale) {
  return bannerTitle;
}

export function bannerImageLink() {
  return bannerLink;
}

export function isBannerEnabled() {
  return bannerEnabled;
}

export function censor(username: String): String {
  const secondPart = username.slice(3, -1);
  return '****' + secondPart;
}
