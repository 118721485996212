import { Search } from './Search'

export const MobileSearch = ({ isVisible }) => {
  return (
    <div 
      className={`bg-dark px-6 py-2 ${!isVisible && "-mt-16"} md:hidden absolute z-20 w-full`}
      style={{transition: "margin 500ms"}}
    >
      <Search className="w-full" />
    </div>
  )
};
