import { useContext } from 'react';

import { GamesContext } from '../context/GamesContext';
import { TranslationsContext } from '../context/TranslationsContext';
import { LazyImage } from './LazyImage';
import { StarButton } from './StarButton';
import { gameImage, gameRibbon } from '../utils/data';

export const Game = ({ game, className = '' }) => {
  const { launchGame, isStarred, starGame, unstarGame } = useContext(GamesContext);
  const { locale } = useContext(TranslationsContext);

  const image = gameImage(game, locale)

  function toggleStarred() {
    if (isStarred(game)) {
      unstarGame(game)
    } else {
      starGame(game)
    }
  }

  const imageSizeMap = [
    ["(min-width: 1280px)", "md"],
    ["", "sm"]
  ]

  return (
    <div className={`relative ${className}`}>
      <LazyImage
        className="
          rounded-modal md:rounded overflow-hidden shadow-game
          custom-badge aspect-h-9 aspect-w-16
        "
        imageClasses="w-full"
        hash={image.blurHash}
        alt={game.name}
        imageSizes={image.hor}
        sizeMap={imageSizeMap}
        fallbackSize="sm"
        retinaSupport
        resolution={2}
        badge={gameRibbon(game, locale)}
      />

      <div 
        className="absolute inset-0 overflow-hidden cursor-pointer"
        onClick={() => launchGame(game)}
      >
      </div>

      <div className="absolute right-0 bottom-0">
        <StarButton isFilled={isStarred(game)} onClick={toggleStarred} />
      </div>
    </div>
  );
};
