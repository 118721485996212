export const Icon = ({ name, className }) => {
  // eslint-disable-next-line default-case
  switch (name) {
    case 'search':
      return (
        <svg className={className} viewBox="0 0 29 31">
          <path
            d="M28.32 28.93l-8.25-8.23a11.59 11.59 0 10-7.78 3 11.45 11.45 0 006.25-1.85L27 30.3a1 1 0 001.34 0 1 1 0 00-.02-1.37zM2.58 12a9.72 9.72 0 1119.44.137A9.72 9.72 0 012.58 12z"
            fill="#666"
            fillRule="nonzero"
          />
        </svg>
      );

    case 'close':
      return (
        <svg className={className} viewBox="0 0 36 36">
          <path
            stroke="currentColor"
            strokeWidth="3"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            d="M2.122 2.122l31.773 31.772M17.155 18.006H18.3M2.121 33.894L33.894 2.122"
          />
        </svg>
      );

    case 'refresh':
      return (
        <svg
          className={className}
          viewBox="0 0 57.393 46.651"
          fill="currentColor"
        >
          <path d="M8.126 32.325l8.13-11.808H-.001z" />
          <path d="M49.267 14.608l-8.131 11.808h16.257z" />
          <path d="M28.697 46.65a23.345 23.345 0 01-17.482-7.882 2.757 2.757 0 01.24-3.892 2.756 2.756 0 013.891.24 17.834 17.834 0 0013.351 6.02 17.83 17.83 0 0017.811-17.811 2.757 2.757 0 012.757-2.757 2.757 2.757 0 012.757 2.757A23.351 23.351 0 0128.697 46.65z" />
          <path d="M8.127 26.082a2.757 2.757 0 01-2.757-2.757A23.352 23.352 0 0128.696 0 23.174 23.174 0 0145.19 6.831a2.758 2.758 0 010 3.9 2.757 2.757 0 01-3.9 0 17.7 17.7 0 00-12.597-5.22 17.831 17.831 0 00-17.812 17.811 2.757 2.757 0 01-2.754 2.76z" />
        </svg>
      );

    case 'user':
      return (
        <svg className={className} viewBox="0 0 18.84 24.293">
          <path d="M55.25-391.788a10.272,10.272,0,0,0,6.784,2.881c.561.024.472,2.056-.089,2.032a12.243,12.243,0,0,1-8.584-3.947,1.018,1.018,0,0,1-.235-.908,9.451,9.451,0,0,1,9.394-6.972,9.451,9.451,0,0,1,9.394,6.972,1.017,1.017,0,0,1-.235.908,11.8,11.8,0,0,1-3.979,2.872,1.017,1.017,0,0,1-1.342-.515,1.017,1.017,0,0,1,.515-1.343,9.835,9.835,0,0,0,2.917-1.979,7.5,7.5,0,0,0-7.27-4.881A7.5,7.5,0,0,0,55.25-391.788Zm7.27-19.381a5.389,5.389,0,0,0-5.387,5.387,5.389,5.389,0,0,0,5.387,5.387,5.389,5.389,0,0,0,5.387-5.387A5.389,5.389,0,0,0,62.521-411.169Zm0,2.034a3.355,3.355,0,0,1,3.353,3.353,3.355,3.355,0,0,1-3.353,3.353,3.355,3.355,0,0,1-3.353-3.353A3.355,3.355,0,0,1,62.521-409.135Z" transform="translate(-53.101 411.169)" fill="#737274" fillRule="evenodd"/>
        </svg>
      )

    case 'gear':
      return (
        <svg className={className} viewBox="0 0 48.44 48.44">
          <g
            transform="translate(-624 585.48)"
            opacity=".4"
            fill="none"
            stroke="currentColor"
            strokeMiterlimit="10"
            strokeWidth="2.29"
          >
            <path d="M651.55-583.4l1.31 3.68a1.4 1.4 0 00.79.83l3.2 1.33a1.4 1.4 0 001.15-.03l3.53-1.68a1.4 1.4 0 011.6.28l2.83 2.83a1.4 1.4 0 01.28 1.6l-1.68 3.53a1.4 1.4 0 00-.03 1.14l1.33 3.21a1.4 1.4 0 00.82.79l3.69 1.3a1.4 1.4 0 01.93 1.33v4a1.4 1.4 0 01-.93 1.33l-3.69 1.31a1.4 1.4 0 00-.82.79l-1.33 3.2a1.4 1.4 0 00.03 1.15l1.68 3.53a1.4 1.4 0 01-.28 1.6l-2.83 2.83a1.4 1.4 0 01-1.6.28l-3.53-1.69a1.4 1.4 0 00-1.14-.03l-3.2 1.33a1.4 1.4 0 00-.8.83l-1.3 3.68a1.4 1.4 0 01-1.33.94h-4.01a1.4 1.4 0 01-1.32-.94l-1.31-3.68a1.4 1.4 0 00-.8-.83l-3.2-1.33a1.4 1.4 0 00-1.14.03l-3.53 1.68a1.4 1.4 0 01-1.6-.27l-2.83-2.84a1.4 1.4 0 01-.28-1.6l1.68-3.53a1.4 1.4 0 00.03-1.14l-1.33-3.2a1.4 1.4 0 00-.83-.8l-3.68-1.3a1.4 1.4 0 01-.93-1.33v-4a1.4 1.4 0 01.93-1.33l3.68-1.3a1.4 1.4 0 00.83-.8l1.33-3.2a1.4 1.4 0 00-.03-1.14l-1.68-3.53a1.4 1.4 0 01.28-1.6l2.83-2.84a1.4 1.4 0 011.6-.27l3.53 1.68a1.4 1.4 0 001.14.02l3.2-1.32a1.4 1.4 0 00.8-.83l1.3-3.68a1.4 1.4 0 011.33-.94h4a1.4 1.4 0 011.33.94z" />
            <circle
              cx="11.17"
              cy="11.17"
              r="11.17"
              transform="translate(637.06 -572.42)"
            />
          </g>
        </svg>
      );

    case 'arrow-left':
      return (
        <svg className={className} viewBox="0 0 29.84 55.67">
          <path
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M27 2.83l-25 25 25 25.01"
          />
        </svg>
      );

    case 'arrow-right':
      return (
        <svg className={className} viewBox="0 0 29.84 55.67">
          <path
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M2.83 2.83l25 25-25 25.01"
          />
        </svg>
      );

    case 'arrow-down':
      return (
        <svg width="15" height="7" viewBox="0 0 15 7">
          <path
            id="Polygon_2"
            data-name="Polygon 2"
            d="M7.5,0,15,7H0Z"
            transform="translate(15 7) rotate(180)"
            fill="#2ac69d"
          />
        </svg>
      );
  }
};
