import { createContext, useState, useEffect } from 'react'
import { debounce } from '../utils/utils'

export const ScrollContext = createContext();

export const ScrollProvider = ({ children }) => {
  const [scrollTop, setScrollTop] = useState(0)

  const onScroll = debounce(() => {
    setScrollTop(window.pageYOffset)
  }, 50)

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  const value = {
    scrollTop,
    onScroll
  }

  return (
    <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>
  );
};
