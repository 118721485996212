import React, { useState, useRef, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { Icon } from './Icon';

export const Carousel = ({ children }) => {
  const scrollable = useRef(null)
  const [canMoveLeft, setCanMoveLeft] = useState(false)
  const [canMoveRight, setCanMoveRight] = useState(true)

  const [props, set] = useSpring(() => ({scrollLeft: 0}))

  function isScrollingPossible() {
    return scrollable.current.scrollWidth > scrollable.current.offsetWidth
  }

  function scrollLeft() {
    return scrollable.current.scrollLeft
  }
  
  function singleStride() {
    return scrollable.current.offsetWidth - (40 + 35)
  }

  function rightEdge() {
    if (!scrollable.current) return
    return scrollable.current.scrollWidth - scrollable.current.clientWidth
  }


  function moveLeft() {
    const intention = scrollLeft() - singleStride()
    set({scrollLeft: Math.max(intention, 0)})
  }
  
  function moveRight() {
    const intention = scrollLeft() + singleStride()
    set({scrollLeft: Math.min(intention, rightEdge())})
  }

  
  useEffect(() => {
    if (!isScrollingPossible()) {
      setCanMoveLeft(false)
      setCanMoveRight(false)
    }
  })

  function onScroll() {
    setCanMoveLeft(scrollLeft() > 200 && isScrollingPossible())
    setCanMoveRight(scrollLeft() < rightEdge() - 200 && isScrollingPossible())
  }

  return (
    <div className="relative overflow-x-hidden">
      <animated.div 
        className="px-4 sm:px-10 carousel-grid scrollbar-hidden" 
        scrollLeft={props.scrollLeft} 
        onScroll={onScroll}
        ref={scrollable}
      >
        {children}
      </animated.div>

      <ArrowButton 
        className="carousel-gradient-left left-0 -ml-10" 
        icon="arrow-left" 
        isVisible={canMoveLeft} 
        onClick={moveLeft} 
      />

      <ArrowButton 
        className="carousel-gradient-right right-0 -mr-10"
        icon="arrow-right" 
        isVisible={canMoveRight} 
        onClick={moveRight} 
      />
    </div>
  );
};

const ArrowButton = ({ className, icon, isVisible, onClick }) => (
  <button 
    className={`
      absolute inset-y-0 hidden md:flex transition-opacity duration-300 
      items-center justify-center w-32 h-full focus:outline-none
      ${isVisible ? "opacity-100" : "opacity-0 pointer-events-none"} ${className}
    `}
    onClick={onClick}
  >
    <Icon name={icon} className="w-6" />
  </button>
)