import React, { Suspense, useContext } from 'react'
import { AnimatePresence } from 'framer-motion'

const fallback = <div className="hidden"></div>

export function ModalWrapper({ children, context, isOpen = null }) {
  const { opened } = useContext(context)
  return (
    <AnimatePresence initial={false}>
      {(isOpen || opened) && (
        <Suspense fallback={fallback}>
          {children}
        </Suspense>
      )}
    </AnimatePresence>
  )
}