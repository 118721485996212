import React, { useContext } from 'react';

import { LazyImage } from './LazyImage';
import { ITag, Locale } from '../utils/data';
import { GamesContext } from '../context/GamesContext';
import { TranslationsContext } from '../context/TranslationsContext';
import { ImageSize, SizeMap } from './Picture';

interface TagProps {
  tag: ITag;
  className?: string;
}

export const Tag: React.FC<TagProps> = ({ tag, className = '' }) => {
  const { setSearch } = useContext(GamesContext);
  const { locale } = useContext(TranslationsContext);

  const title = tag.title[locale as Locale]
  const image = tag.image[locale as Locale]

  const imageSizeMap: SizeMap = [
    ["(min-width: 1280px)", ImageSize.Medium],
    ["", ImageSize.Small]
  ]

  function onClick() {
    setSearch(title);
  }

  return (
    <div 
      className={`cursor-pointer ${className}`}
      onClick={onClick}
    >
      <LazyImage
        className="
          rounded-modal md:rounded overflow-hidden shadow-game 
          aspect-h-9 aspect-w-16
        "
        imageClasses="w-full"
        hash={tag.blurHash}
        alt={title}
        imageSizes={image}
        sizeMap={imageSizeMap}
        fallbackSize={ImageSize.Small}
        resolution={2}
      />
    </div>
  );
};
