import React from "react";
import { Carousel } from "./Carousel";
import { Game } from "./Game";
import { IGame } from "../utils/data"

interface GameCarouselProps {
  games: IGame[]
}

export const GameCarousel: React.FC<GameCarouselProps> = ({ games }) => (
  <Carousel>
    {games.map((game, index) => (
      <div key={index}>
        <Game game={game} className="xl:max-w-none" />
      </div>
    ))}
  </Carousel>
)