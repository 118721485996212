import { useContext } from 'react';
import { WinnersContext } from '../context/WinnersContext';

const src = "/images/big-winners-sm.png";

export const BigWinners = ({ className }) => {
  const { toggle } = useContext(WinnersContext);
  return (
    <button
      onClick={toggle}
      type="button"
      className={`absolute transform -translate-x-1/2 top-2 left-1/2 focus:outline-none ${className}`}
    >
      <img className="md:hidden" width="56" height="56" src={src} />
      <img className="hidden md:block" width="84" height="84" src={src} />
    </button>
  );
};
