import { bannerImageLink } from '../utils/data';

export const BannerLogo = ({ className = '' }) => {
  return (
    <a href={bannerImageLink()} target="_blank">
      <svg
        className={className}
        width="58"
        viewBox="0 0 109 109"
      >
        <linearGradient
          id="a"
          gradientUnits="userSpaceOnUse"
          x1="-8.47"
          y1="69.7"
          x2="431.53"
          y2="-36.3"
        >
          <stop offset="0" stopColor="#21e418" />
          <stop offset=".3" stopColor="#31b6e9" />
          <stop offset=".98" stopColor="#21e418" />
        </linearGradient>
        <path
          d="M54.5 0C24.4 0 0 24.4 0 54.6c0 5.4.8 10.7 2.4 15.8 1-1.4 2-2.8 2.9-4.3 3.8-5.6 7.5-11.2 11.2-16.9l2.7-4.3c.9-1.4 1.8-2.9 2.6-4.3 1.8-2.9 3.5-5.8 5.1-8.7.8-1.5 1.6-2.9 2.5-4.4s1.6-2.9 2.4-4.4 1.6-3 2.3-4.4l2.2-4.5.2-.3c1.2-2.4 4.1-3.4 6.6-2.3 1.8 1 2.9 2.9 2.8 5 0 2.6-.5 5.3-.7 7.9s-.3 5.3-.5 8c-.2 5.3-.3 10.7-.3 16s.3 10.6.8 15.7c.3 2.5.7 5 1.2 7.5.4 2.2 1.1 4.4 2.1 6.4.3.6.7 1.2 1.2 1.7.3.2.1.1.3.1h.2l.2-.1h.1l.3-.1c.1-.1.3-.2.4-.3.8-.6 1.5-1.3 2.2-2.1 1.6-1.9 3.1-3.8 4.4-5.9 1.4-2.1 2.7-4.4 4-6.7 2.6-4.6 5-9.3 7.3-14.1 1.1-2.4 2.3-4.8 3.4-7.3 1.1-2.4 2.2-4.9 3.2-7.3a4.94 4.94 0 019.4 1c0 .6.1 1.4.2 2.2l.3 2.3c.2 1.5.4 3 .7 4.5.5 3 1.1 6 1.9 8.9.8 2.9 1.8 5.8 3 8.6s2.7 5.4 4.6 7.8c.4.6.3 1.4-.2 1.8-.5.4-1.2.4-1.6 0-2.5-2.2-4.7-4.7-6.6-7.4-1.9-2.7-3.5-5.6-5-8.5-1-2-1.8-4-2.7-6-.6 1.2-1.1 2.4-1.7 3.5-2.4 4.9-4.8 9.8-7.6 14.7-1.4 2.6-2.8 5-4.4 7.4a62.4 62.4 0 01-5.3 7.1c-1.1 1.3-2.4 2.4-3.7 3.5-.4.3-.8.6-1.3.8l-.9.5-.5.2h-.1l-.3.1h-.2l-.4.1c-.3.1-.5.2-.8.2l-1.8.3c-1.3.1-2.7-.1-3.9-.5-1.2-.4-2.3-1.1-3.3-1.9-1.5-1.3-2.8-2.9-3.7-4.7a34.6 34.6 0 01-3-8.9c-.6-2.8-1-5.7-1.3-8.5-.6-5.6-.8-11.1-.9-16.7v-7.9c-1 1.9-2 3.8-3 5.6-.8 1.5-1.6 3-2.5 4.5l-2.5 4.5c-3.4 6-6.9 11.9-10.6 17.8-2.3 3.8-4.7 7.6-7.1 11.3 16.5 25.2 50.3 32.2 75.5 15.7s32.2-50.3 15.7-75.5A54.98 54.98 0 0054.5 0z"
          fill="url(#a)"
        />
      </svg></a>
  )
}
