import { Button } from './Button';
import { LazyImage } from './LazyImage';

export const MobilePromo = ({ image, imageAlt, onClick }) => {
  const imageSizeMap = [
    ["(min-width: 750px)", "lg"],
    ["(min-width: 350px)", "md"],
    ["", "sm"],
  ]

  return (
    <div className="relative lg:hidden pb-16 md:pb-12 overflow-hidden" style={{maxHeight: "600px"}}>
      <LazyImage
        className="w-full h-full"
        canvasStyle={{height: "calc(100vw * 9 / 16)"}}
        imageClasses="object-left w-full h-full"
        width={window.innerWidth}
        height={window.innerWidth * 9 /16}
        hash={image.blurHash}
        alt={imageAlt}
        imageSizes={image.hor}
        sizeMap={imageSizeMap}
        fallbackSize="md"
        resolution={10}
        blurhash={false}
      />

      {/*<div*/}
      {/*  className="absolute inset-0 flex items-end justify-center pb-10 md:pb-16"*/}
      {/*  style={{background: "linear-gradient(to top, #010103, #010103 50px, transparent 120px)"}}*/}
      {/*>*/}
      {/*  <Button className="text-xl pointer-events-auto h-14 px-14 shadow-button"*/}
      {/*    onClick={onClick}>i18n.set.promo.button</Button>*/}
      {/*</div>*/}
    </div>
  );
};
