import { Text } from './Text';

export const Table = ({ children, className = '' }) => {
  return <table className={`table-separate w-full ${className}`}>{children}</table>;
};

export const TableHeading = ({ 
  children, 
  className = '', 
  cellClassName = '',
  first = false, 
  last = false 
}) => {
  return (
    <th
      className={`text-sm pb-3 font-bold whitespace-no-wrap text-white text-opacity-30 uppercase border-b border-gray-600 ${
        first ? 'text-left' : ''
      } ${cellClassName}`}
    >
      <div className={className}>
        <Text translate>{children}</Text>
      </div>
    </th>
  );
};

export const TableCell = ({
  children,
  className = '',
  cellClassName = '',
  paddingX = 'px-2 sm:px-4',
  first = false,
  last = false,
}) => {
  return (
    <td
      className={`leading-none ${paddingX} bg-secondary ${
        first ? 'rounded-l-lg text-left' : 'text-center'
      } ${last ? 'rounded-r-lg' : ''} ${cellClassName}`}
    >
      <div
        className={`relative whitespace-no-wrap text-white text-base ${className}`}
      >
        {children}
      </div>
    </td>
  );
};
