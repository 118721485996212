import React from "react";
import { Carousel } from "./Carousel";
import { ITag } from "../utils/data";
import { Tag } from "./Tag";

interface TagCarouselProps {
  tags: ITag[]
}

export const TagCarousel: React.FC<TagCarouselProps> = ({ tags }) => (
  <Carousel>
    {tags.map((tag, index) => (
      <div key={index}>
        <Tag tag={tag} className="xl:max-w-none" />
      </div>
    ))}
  </Carousel>
)